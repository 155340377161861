import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { DateTime } from 'luxon';

export enum WelcomeCardTheme {
  'default' = 'default',
  'demo' = 'demo',
  'confetti' = 'confetti',
}

@Component({
  selector: 'app-welcome-card',
  templateUrl: './welcome-card.component.html',
  styleUrls: ['./welcome-card.component.scss'],
})
export class WelcomeCardComponent implements OnInit {
  @Input() theme: WelcomeCardTheme = environment.demo ? WelcomeCardTheme.demo : WelcomeCardTheme.default;

  welcomeMessage = '';

  confettiPositions: string[] = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%'];

  ngOnInit(): void {
    switch (this.theme) {
      case WelcomeCardTheme.confetti:
        this.welcomeMessage = 'home.new_year_welcome';
        break;
      case WelcomeCardTheme.demo:
        this.welcomeMessage = 'home.welcome_demo';
        break;
      default:
        this.welcomeMessage = 'home.welcome';
    }
  }

  protected endYearBannerAppear: boolean = DateTime.now().toMillis() > DateTime.fromFormat('31/01/2025 23:59:59', 'dd/MM/yyyy hh:mm:ss').toMillis();

  protected readonly WelcomeCardTheme = WelcomeCardTheme;

  protected readonly DateTime = DateTime;
}
