import { Component } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-end-year-banner',
  standalone: true,
  imports: [],
  templateUrl: './end-year-banner.component.html',
  styleUrl: './end-year-banner.component.scss',
})
export class EndYearBannerComponent {
  now = DateTime.now();

  message: string = '';

  constructor() {
    if (this.now.toMillis() < DateTime.fromFormat('31/12/2024 23:59:59', 'dd/MM/yyyy hh:mm:ss').toMillis()) {
      this.message = 'Les équipes d’Alterna énergie <br>vous souhaitent de joyeuses fêtes';
    } else {
      this.message = 'Les équipes d’Alterna énergie <br>vous souhaitent une Bonne Année';
    }
  }
}
