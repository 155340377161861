import { NgModule } from '@angular/core';
import { TableFiltersComponent } from '@app/shared/components/table-filters/table-filters.component';
import { SelectorComponent } from '@app/shared/components/organisms/selector/selector.component';
import { PipeModule } from '@app/shared/modules/pipe.module';
import { SharedLibsModule } from '@app/shared/shared-libs.module';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { ButtonComponent } from '@app/shared/components/molecules/buttons/button.component';
import { MainInformationComponent } from '@app/shared/components/molecules/main-information/main-information.component';
import { FluidIconsComponent } from '@app/shared/components/atoms/fluid-icons/fluid-icons.component';
import { HasAnyRightDirective } from '@app/shared/directives/has-any-right.directive';
import {
  CustomSkeletonLoaderComponent,
} from '@app/shared/components/atoms/custom-skeleton-loader/custom-skeleton-loader.component';
import { MainLoaderComponent } from '@app/shared/components/atoms/loaders/main-loader/main-loader.component';
import { AlertComponent } from '@app/shared/components/organisms/alert/alert.component';
import {
  FeatureNotExistOrgComponent,
} from '@app/shared/components/organisms/feature-not-exist-org/feature-not-exist-org.component';
import { StandardImageComponent } from '@app/shared/components/atoms/image/standard-image/standard-image.component';
import { LabelComponent } from '@app/shared/components/atoms/text/label/label.component';
import { LoginHeaderComponent } from '@app/user-account/login-header/login-header.component';
import {
  FluidToggleSwitchComponent,
} from '@app/shared/components/atoms/switch/toggle-fluid-switch/fluid-toggle-switch.component';
import { TranslateDirective } from '@app/shared/translate/translate.directive';
import {
  PaginatorContainerComponent,
} from '@app/shared/components/paginator/paginator-container/paginator-container.component';
import { SubSectionComponent } from '@app/shared/components/organisms/sub-section/sub-section.component';
import { SubSectionItemComponent } from '@app/shared/components/molecules/sub-section-item/sub-section-item.component';
import {
  DashboardCardComponent,
} from '@app/shared/components/molecules/dashboard-card/dashboard-card.component';
import { LinkComponent } from '@app/shared/components/atoms/link/link.component';
import { RouterLink, RouterModule } from '@angular/router';
import { PieChartComponent } from '@app/shared/components/highcharts/pie-chart/pie-chart.component';
import { ExpansionPanelComponent } from '@app/shared/components/molecules/expansion-panel/expansion-panel.component';
import { HighchartsComponent } from '@app/shared/components/highcharts/highcharts.component';
import {
  WarningMessageBlockComponent,
} from '@app/shared/components/molecules/warning-message-block/warning-message-block.component';
import { ToggleSwitchComponent } from '@app/shared/components/atoms/switch/toggle-switch/toggle-switch.component';
import { ClickAwayDirective } from '@app/shared/directives/click-away.directive';
import {
  HistogramLoaderComponent,
} from '@app/shared/components/atoms/loaders/histogram-loader/histogram-loader.component';
import { CurveLoaderComponent } from '@app/shared/components/atoms/loaders/curve-loader/curve-loader.component';
import { BlocDetailSiteComponent } from '@app/shared/components/organisms/bloc-detail-site/bloc-detail-site.component';
import {
  SpecialDaysLegendComponent,
} from '@app/shared/components/highcharts/special-days-legend/special-days-legend.component';
import { LeafLoaderComponent } from '@app/shared/components/atoms/loaders/leaf-loader/leaf-loader.component';
import {
  IconTextTitleCardComponent,
} from '@app/shared/components/molecules/icon-text-title-card/icon-text-title-card.component';
import { TabSwitchComponent } from '@app/shared/components/atoms/switch/tab-switch/tab-switch.component';
import { SwitchComponent } from '@app/shared/components/atoms/switch/switch.component';
import { RadioButtonComponent } from '@app/shared/components/atoms/radio-button/radio-button.component';
import { RadioGroupComponent } from '@app/shared/components/molecules/radio-group/radio-group.component';
import { DialogFooterDirective } from '@app/shared/components/atoms/dialog/dialog-footer.directive';
import { DialogHeaderDirective } from '@app/shared/components/atoms/dialog/dialog-header.directive';
import { DialogContentDirective } from '@app/shared/components/atoms/dialog/dialog-content.directive';
import { DailyDialogComponent } from '@app/shared/components/molecules/dialog/daily-dialog/daily-dialog.component';
import { BaseDialogComponent } from '@app/shared/components/atoms/dialog/base-dialog.component';
import { MonthlyDialogComponent } from '@app/shared/components/molecules/dialog/monthly-dialog/monthly-dialog.component';
import {
  ChangePasswordDialogComponent,
} from '@app/shared/components/molecules/dialog/change-password-dialog/change-password-dialog.component';
import {
  ConfirmChangePasswordDialogComponent,
} from '@app/shared/components/molecules/dialog/confirm-change-password-dialog/confirm-change-password-dialog.component';
import { IconComponent } from '@app/shared/components/atoms/icon/icon.component';
import { DividerComponent } from '@app/shared/components/atoms/divider/divider.component';
import { SpinnerComponent } from '@app/shared/components/atoms/loaders/spinner/spinner.component';
import { TableHeaderDirective } from '@app/shared/components/atoms/table/directives/table-header.directive';
import { CellDirective } from '@app/shared/components/atoms/table/directives/cell.directive';
import { ColumnHeaderDirective } from '@app/shared/components/atoms/table/directives/column-header.directive';
import { TableCellDirective } from '@app/shared/components/atoms/table/directives/table-cell.directive';

import { CheckComponent } from '@app/shared/components/molecules/password-checker/check/check.component';
import { BlockCopyPasteDirective } from '@app/shared/directives/block-copy-paste.directive';
import { PasswordCheckerComponent } from '@app/shared/components/molecules/password-checker/password-checker.component';
import { BaseTableComponent } from '@app/shared/components/atoms/table/base-table.component';
import { RowDirective } from '@app/shared/components/atoms/table/directives/row.directive';
import { RowCellsDirective } from '@app/shared/components/atoms/table/directives/row-cells.directive';
import { RowHeadersDirective } from '@app/shared/components/atoms/table/directives/row-headers.directive';
import { ColumnHeaderComponent } from '@app/shared/components/atoms/table/column-header/column-header.component';
import { TableFooterComponent } from '@app/shared/components/atoms/table/table-footer/table-footer.component';
import { StickyDirective } from '@app/shared/components/atoms/table/directives/sticky.directive';
import { ColumnDirective } from '@app/shared/components/atoms/table/directives/column.directive';
import { BadgeNotificationComponent } from '@app/shared/components/atoms/badge-notification/badge-notification.component';
import { ContactButtonComponent } from '@app/shared/components/molecules/buttons/contact-button/contact-button.component';
import { CollapseRowDirective } from '@app/shared/components/atoms/table/directives/collapse-row.directive';
import { CollapseCellDirective } from '@app/shared/components/atoms/table/directives/collapse-cell.directive';
import { TableDirective } from '@app/shared/components/atoms/table/directives/table.directive';
import { AlertCardComponent } from '@app/shared/components/molecules/alert-card/alert-card.component';
import { TextfieldComponent } from '@app/shared/components/atoms/textfield/textfield.component';
import { EndYearBannerComponent } from '@app/shared/components/end-year-banner/end-year-banner.component';
import { LabelValueListDisplayerComponent } from './components/molecules/label-value-list-displayer/label-value-list-displayer.component';
import { TooltipComponent } from './components/atoms/tooltip/tooltip.component';
import { TooltipDirective } from './components/atoms/tooltip/tooltip.directive';
import { AutocompleteInputComponent } from './components/molecules/autocomplete-input/autocomplete-input.component';
import { FormInputComponent } from './components/atoms/form-input/form-input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ToastComponent } from './components/toast/toast.component';
import { ChipComponent } from './components/atoms/chip/chip.component';
import { RangepickerComponent } from './components/rangepicker/rangepicker.component';
import { CarouselItemDirective } from './components/molecules/carousel/carousel-item.directive';
import { EditDialogComponent } from './components/molecules/dialog/edit-dialog/edit-dialog.component';

@NgModule({
  imports: [
    SharedLibsModule,
    PipeModule,
    RouterModule,
    CheckComponent,
    LabelComponent,
    ButtonComponent,
    BlockCopyPasteDirective,
    PasswordCheckerComponent,
    IconComponent,
    WarningMessageBlockComponent,
    RangepickerComponent,
    SpinnerComponent,
    TooltipComponent,
    TooltipDirective,
    ExpansionPanelComponent,
    TableDirective,
    AlertCardComponent,
    BadgeNotificationComponent,
    CustomSkeletonLoaderComponent,
    ChipComponent,
    BaseDialogComponent,
    DividerComponent,
    FluidIconsComponent,
    FormInputComponent,
    TextfieldComponent,
    StandardImageComponent,
    LinkComponent,
    CurveLoaderComponent,
    HistogramLoaderComponent,
    LeafLoaderComponent,
    MainLoaderComponent,
    RadioButtonComponent,
    TabSwitchComponent,
    FluidToggleSwitchComponent,
    ToggleSwitchComponent,
    SwitchComponent,
    TableFooterComponent,
    SelectorComponent,
    ColumnHeaderComponent,
    BaseTableComponent,
    CheckboxComponent,
    SelectorComponent,
    BaseTableComponent,
    TableFooterComponent,
    EndYearBannerComponent,
    EndYearBannerComponent,
  ],
  declarations: [
    ContactButtonComponent,
    AutocompleteInputComponent,
    RadioGroupComponent,
    TableFiltersComponent,
    PaginatorComponent,
    PaginatorContainerComponent,
    MainInformationComponent,
    HasAnyRightDirective,
    TranslateDirective,
    AlertComponent,
    FeatureNotExistOrgComponent,
    LoginHeaderComponent,
    LabelValueListDisplayerComponent,
    SubSectionComponent,
    SubSectionItemComponent,
    DashboardCardComponent,
    PieChartComponent,
    HighchartsComponent,
    ClickAwayDirective,
    ToastComponent,
    BlocDetailSiteComponent,
    SpecialDaysLegendComponent,
    IconTextTitleCardComponent,
    DialogFooterDirective,
    DialogHeaderDirective,
    DialogContentDirective,
    DailyDialogComponent,
    ChangePasswordDialogComponent,
    ConfirmChangePasswordDialogComponent,
    MonthlyDialogComponent,
    CarouselItemDirective,
    TableHeaderDirective,
    CellDirective,
    ColumnHeaderDirective,
    ColumnDirective,
    RowDirective,
    TableCellDirective,
    CollapseCellDirective,
    RowCellsDirective,
    CollapseRowDirective,
    RowHeadersDirective,
    StickyDirective,
    EditDialogComponent,
  ],
  exports: [
    TextfieldComponent,
    ContactButtonComponent,
    FormInputComponent,
    AutocompleteInputComponent,
    PipeModule,
    LoginHeaderComponent,
    FeatureNotExistOrgComponent,
    AlertComponent,
    CustomSkeletonLoaderComponent,
    FluidIconsComponent,
    MainInformationComponent,
    LinkComponent,
    CheckboxComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    TableFiltersComponent,
    SelectorComponent,
    PaginatorComponent,
    PaginatorContainerComponent,
    HasAnyRightDirective,
    TranslateDirective,
    MainLoaderComponent,
    FluidToggleSwitchComponent,
    LabelValueListDisplayerComponent,
    SubSectionComponent,
    SubSectionItemComponent,
    DashboardCardComponent,
    PieChartComponent,
    ClickAwayDirective,
    HighchartsComponent,
    ToggleSwitchComponent,
    ToastComponent,
    HistogramLoaderComponent,
    CurveLoaderComponent,
    BlocDetailSiteComponent,
    SpecialDaysLegendComponent,
    LeafLoaderComponent,
    IconTextTitleCardComponent,
    ChipComponent,
    TabSwitchComponent,
    SwitchComponent,
    DialogFooterDirective,
    DialogHeaderDirective,
    DialogContentDirective,
    DailyDialogComponent,
    MonthlyDialogComponent,
    ChangePasswordDialogComponent,
    ConfirmChangePasswordDialogComponent,
    BaseDialogComponent,
    DividerComponent,
    CarouselItemDirective,
    TableHeaderDirective,
    CellDirective,
    ColumnHeaderDirective,
    RowDirective,
    RowCellsDirective,
    CollapseCellDirective,
    CollapseRowDirective,
    TableCellDirective,
    BaseTableComponent,
    RowHeadersDirective,
    ColumnHeaderComponent,
    TableFooterComponent,
    StickyDirective,
    BadgeNotificationComponent,
    ColumnDirective,
    StandardImageComponent,
    EditDialogComponent,
  ],
  providers: [RouterLink],
})
export class SharedCommonModule {
  static forRoot() {
    return {
      ngModule: SharedCommonModule,
    };
  }
}
